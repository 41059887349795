<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6>
							<a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> 
							<button class="btn btn-default ml-3"  @click="toRedirect('agent-edit')">Agent Info</button>
							<button class="btn btn-primary ml-3">Dashboard</button>
							<button class="btn btn-default ml-3" @click="toRedirect('agent-order')">Order</button>
							<button class="btn btn-default ml-3" @click="toRedirect('agent-receive')">Receive</button>
						</h6>

					</div>
                    <div class="card-body">
                        <div class="row mt-2">
                            <div class="col-md-4">
                                <div class="card" style="width: 100%;">
                                    <div class="card-header">Daily Commissionable</div>
                                    <div class="card-body">
                                        <p class="card-text"><a href="#" style="text-decoration: none;color: #000000;">£ {{dashboard.dailyComAble}}</a></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card" style="width: 100%;">
                                    <div class="card-header">Weekly Commissionable</div>
                                    <div class="card-body">
                                        <p class="card-text"><a href="#" style="text-decoration: none;color: #000000;">£ {{dashboard.weeklyComAble}}</a></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card" style="width: 100%;">
                                    <div class="card-header">Monthly Commissionable</div>
                                    <div class="card-body">
                                        <p class="card-text"><a href="#" style="text-decoration: none;color: #000000;">£ {{dashboard.monthlyComAble}}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-4">
                                <div class="card" style="width: 100%;">
                                    <div class="card-header">Life Time Commissionable</div>
                                    <div class="card-body">
                                        <p class="card-text"><a href="#" style="text-decoration: none;color: #000000;">£ {{dashboard.lifeTimeComAble}}</a></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card" style="width: 100%;">
                                    <div class="card-header">Life Time Commission Amount</div>
                                    <div class="card-body">
                                        <p class="card-text"><a href="#" style="text-decoration: none;color: #000000;">£ {{dashboard.lifeTimeComAmount}}</a></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card" style="width: 100%;">
                                    <div class="card-header">Commission Percent</div>
                                    <div class="card-body">
                                        <p class="card-text"><a href="#" style="text-decoration: none;color: #000000;">{{dashboard.percentage}} %
                                            </a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-4">
                                <div class="card" style="width: 100%;">
                                    <div class="card-header">Promocode</div>
                                    <div class="card-body">
                                        <p class="card-text"><a href="#" style="text-decoration: none;color: #000000;">{{dashboard.code}}</a></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card" style="width: 100%;">
                                    <div class="card-header">Order Count</div>
                                    <div class="card-body">
                                        <p class="card-text"><a href="#" style="text-decoration: none;color: #000000;">{{dashboard.orderCount}}</a></p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
			</div>
		</div>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css'
    export default {
        name: 'dashboard',
        metaInfo: {
            title: "Home",
            titleTemplate: "%s ← SHRE Dashboard",
        },
        components: {
            Loading
        },
        data() {
            return {
                agentId : "",
                isLoading: false,
                fullPage: true,
                dashboard: [],
            }
        },
        computed: {
            ...mapState({})
        },
        methods: {
            ...mapActions({
                agentDashboardAction: 'agentDashboardAction',
            }),
            async dashboardDetail() {
                this.isLoading = true;
                let option = {
                    id : this.agentId
                }
                await this.agentDashboardAction({
					...option
				}).then(res => {
                    if (res.status == "success") {
                        this.dashboard = res.data
                        this.isLoading = false
                    }
				}).catch(err => this.isLoading = true)
            },
            back() {
				this.$router.replace({ path: 'agent-list', query: { page: this.filter.page, orderBy: this.filter.orderBy, sortKey: this.filter.sortKey, name: this.filter.name} }).catch(()=>{})
			},
            toRedirect(key) {
                this.$router.replace({ path: key, query: { page: 1, orderBy: 'desc', sortKey: 'created_at', limit: 10, id: this.agentId, email: '', orderNo: '' } }).catch(()=>{})
            },
        },
        async mounted() {
            this.isLoading = true
            this.agentId = this.$route.query.id
            this.dashboardDetail()
        }
    }
</script>


<style scoped lang="scss">
</style>